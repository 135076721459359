body {
    background-color: #010101;
    color: white;
    /* background-color: white;
    color: black;  */
}
.icon-color{
    color: #ff9800;
}
#experience {
    padding-bottom: 100px;
}
.certi-container{
    background-color: #ffffff0f;
    border: 1px solid #ff980038;
    border-radius: 3px;
    height: 100%;
}
.certi-container .card-text{
    font-size: 13px;
}
.certi-container a{
    padding: 4px 10px;
    font-size: 14px;
}
.middle-content {
    text-align: center;
}

.time-circle {
    position: relative;
    top: 5px;
    border:3px solid #000;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 2px #ff9800;
    z-index: 9;
    font-size: 12px;
}

.right-content {
    display: block;
    padding: 10px;
    float: right;
}
.left-content {
    text-align:right;
}
.right-content ul {
    list-style-type: none
}

.vert-line {
    height: calc(100% + 20px);
    background-color: white;
    width: 2px;
    margin: 0 auto;
    position: relative;
    top: -20px;
}

.experience-content {
    margin-bottom: 20px;
}

.portfolio-card {
    /* padding: 0px; */
    margin-bottom: 0px;
    height: 100%;
    /* border: none; */
    background-color: #ffffff0f;
    border:1px solid #ff980038;
}

.bg-portfolio {
    /* background-color: #010101; */
}

.certi-border {
    /* border: 1px solid orange;
    padding: 10px; */
}

.skill-tags {
    border: 1px solid #ff9800;
    display: inline-block;
    padding: 3px 10px;
    margin: 5px;
    border-radius: 7px;
}

ul {
    margin: 0px;
    padding: 0px;
}

a .fa {
    text-decoration: none;
    color: #fff;
}

.section-title {
    padding: 50px 0 30px;
}
.card-title{
    color: #ff9800;
}
.my-progress-title {
    margin-bottom: 4px;
    font-weight: bold;
    margin-top: 10px;
}
.btn-primary:active{
    background-color: #ff9800;
    border: 0px solid;
}

.my-progress-title span {
    font-weight: normal;
}

.progress {
    border: 1px solid #ff9800;
    padding: 3px;
    background-color: #ffffff00;
    height: 14px;
    border-radius: 10px;
}

.progress-bar {
    background-color: #ff9800;
    border-radius: 10px;
}

.btn {
    border: 1px solid #ff98008f;
    background-color: #ffffff00;
}

.btn:hover, .btn:active, .btn:focus, .btn-primary:not(:disabled):not(.disabled):active  {
    border: 1px solid #ff98008f;
    background-color: #ff9800;
    box-shadow:0 0 0 0.2rem #ff980052;
}

.about-content {
    font-weight: bold;
    text-align: justify;
  min-height: 600px;
  /* padding-left: 50px; */
}
.about-content .card-title {
margin-top: 7em !important;
}
#home{
    background-image: url("../src/images/welcome-background.JPG");
    background-size: cover;
    background-position: center;

}

.wel-img {
    max-width: 70%;
}

.bg-color {
    background-color: #fff0;
}

.container {
    padding: 0px;
    max-width: 1240px;
}

/*
   * Custom translucent site header #373737
   */

.site-header {

    background-color: rgb(0 0 0 / 40%);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    border-bottom: 1px solid #ffa50038;
}

.site-header a {
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
    text-shadow: 0px 0px 2px #000000;
    /* */
}
.site-header a:hover {
    color: #ff9800;
    text-decoration: none;
}

/*
   * Dummy devices (replace them with your own or something else entirely!)
   */

.product-device {
    position: absolute;
    right: 10%;
    bottom: -30%;
    width: 300px;
    height: 540px;
    background-color: #333;
    border-radius: 21px;
    transform: rotate(30deg);
}

.product-device::before {
    position: absolute;
    top: 10%;
    right: 10px;
    bottom: 10%;
    left: 10px;
    content: "";
    background-color: rgba(255, 255, 255, .1);
    border-radius: 5px;
}

.product-device-2 {
    top: -25%;
    right: auto;
    bottom: 0;
    left: 5%;
    background-color: #e5e5e5;
}
.experience-content h4{
    color: #ff9800;
}
.about-content i{
    color: #ff9800;
}
/*
   * Extra utilities
   */

.flex-equal>* {
    flex: 1;
}

@media (min-width: 768px) {
    .flex-md-equal>* {
        flex: 1;
    }
}

@media (max-width: 768px) {
    .flex-md-equal>* {
        flex: 1;
    }
    .container{
        width: 85%;
    }

    .left-content,.right-content {
    text-align: left;
     }
     .left-content p.mt-1{
        color: #ff9800;
        margin-bottom: 0;
    padding-top: 10px;
     }
     .middle-content {
        display: none;
     }

.experience-content{
     border:1px solid #ff980038;
}
.section-title{
    text-align: center;
}
}
.badge-secondary{
    background-color: #ff980038;
    font-weight: normal;
}
/* @media only screen and (max-width: 768px) {
    
    .middle-content,.left-content,.right-content {
       width: 33%;
    }
   
  } */